<template>
  <div>
    <b-overlay rounded="sm" :show="isLoading">
      <div class="card card-custom">
        <div class="card-header border-0 pt-6 pb-0">
          <div class="card-title align-items-start flex-column">
            <h3 class="card-label font-weight-bolder">
              {{ $t("National Contracts") }}
            </h3>
            <span class="text-muted font-weight-bold font-size-sm mt-1">
              {{ $t("Update nation's contracts") }}
            </span>
          </div>
          <div>
            <b-button variant="light-primary" block class="font-weight-bolder"
              @click="createItemClick('national-contract-modal')">
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Files/File.svg" />
              </span>
              {{ $t("Add New Contract") }}
            </b-button>
          </div>
        </div>
        <div class="card-body detail">
          <div v-if="nationalContract">
            <v-jstree :data="treeItems" size="large" whole-row @item-click="itemClick">
              <template slot-scope="_">
                <i class="mdi mdi-file-document-outline text-primary" style="font-size: 25px" role="presentation"></i>
                {{ _.model.name }}
                <button v-if="_.model.name != 'National Contracts'"
                  style="border: 0px; background-color: transparent; cursor: pointer;"
                  @click="editItemClick('national-contract-modal', _.model)">
                  <i class="mdi mdi-pencil text-primary ml-2" style="font-size: 20px" role="presentation"></i>
                </button>
                <button v-if="_.model.name != 'National Contracts'"
                  style="border: 0px; background-color: transparent; cursor: pointer;" @click="openDeleteDialog(_.model)">
                  <i class="mdi mdi-trash-can text-primary ml-2" style="font-size: 20px" :title="$t('Delete')"></i>
                </button>

              </template>
            </v-jstree>
            <NationalContractModal :existing-form="editForm" :national-contract="getNationalContract" :nation="nation"
              @refresh="loadNationContract"></NationalContractModal>
          </div>
          <div v-else>
            <p>{{ this.$t("The selected country does not have any contracts") }}.</p>
          </div>
        </div>
      </div>
    </b-overlay>
    <div v-if="showTable" ref="contract-table" class="card card-custom mt-7">
      <div class="card-body detail">
        <national-contract-level-table :national-contract="getNationalContract"
          @refresh-contract="refresh"></national-contract-level-table>
      </div>
    </div>

  </div>
</template>
<script>
import EmployeeNationalContractService from "@/core/services/employee/employee-nationalcontract.service";
import EmployeeNationalContractLevelService from "@/core/services/employee/employee-nationalcontract-level.service";
import { backendErrorSwal, confirmationSwal } from "@/core/helpers/swal";
import NationalContractModal from "@/view/components/modals/nations/NationalContractModal.vue";
import NationalContractLevelTable from "@/view/components/tables/NationalContractLevelTable.vue";
import Swal from "sweetalert2";
import VJstree from 'vue-jstree';
export default ({
  components: {
    VJstree,
    NationalContractLevelTable,
    NationalContractModal
  },
  props: {
    nation: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      treeItems: [],
      editForm: {},
      nodeId: "",
      showTable: false,
      nationalContract: null,
      isLoading: true,
    }
  },
  computed: {
    getNationalContract() {
      return this.nationalContract.filter(el => el.id == this.nodeId)[0];
    }
  },

  async mounted() {
    await this.loadNationContract();
    this.isLoading = false;

  },
  methods: {
    refresh() {
      this.loadNationContract()
    },
    async loadNationContract() {
      try {
        await EmployeeNationalContractService.getAllNotPaginatedByCountry(this.nation.iso).then((res) => {
          this.nationalContract = res;
          this.treeItems = treeView(this.nationalContract);
        });
      } catch (err) {
        console.log(err);
        backendErrorSwal(err?.response?.data?.detail, err);
      }
    },
    async editItemClick(id, node) {
      this.showTable = false;
      this.nodeId = node.id;
      this.editForm = { ...node };
      this.$bvModal.show(id);
    },

    createItemClick(id) {
      this.editForm = {};
      this.$bvModal.show(id);
    },
    async openDeleteDialog(node) {
      let id = node.id;

      let { isConfirmed } = await confirmationSwal({ title: this.$t("This national contract will be deleted, are you sure?") })
      if (isConfirmed) {
        try {
          await EmployeeNationalContractService.delete(id);
          Swal.fire(this.$t("Success"), this.$t("Contract level deleted"), "success");
          this.showTable = false;
          await this.loadNationContract()
          this.showTable = false;
        } catch (err) {
          let message = "";
          if (err?.response?.status === 400) {
            message = err.response.data.message;
          } else {
            message = err?.response?.data?.message || this.$t("Unable to delete the contract level");
          }
          backendErrorSwal(message, err);
        }
      }

    },

    itemClick(node) {
      this.nodeId = node.model.id;
      this.showTable = true;
    },

    async removeLevel(item) {
      await Swal.fire({
        title: this.$t("This contract level will be deleted, are you sure?"),
        icon: "warning",
        confirmButtonText: this.$t("Yes delete"),
        showCancelButton: true,
        cancelButtonText: this.$t("No, do NOT delete"),
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await EmployeeNationalContractLevelService.delete(item.id);
            Swal.fire(this.$t("Success"), this.$t("Contract level deleted"), "success");
            await this.loadNationContract();
          } catch (err) {
            let message = "";
            if (err?.response?.status === 400) {
              message = err.response.data;
            } else {
              message = err?.response?.data?.detail ?? this.$t("Unable to delete the contract level");
            }
            backendErrorSwal(message, err);
          }
        }
      });
    }
  }
})
function treeView(arr) {
  const items = [];

  items.push({
    name: "National Contracts",
    id: 1,
    children: [],
    parent: null,
    opened: true,
    disabled: true
  });

  arr.forEach(el => {
    items[0].children.push(el)
  });
  return items;
}
</script>