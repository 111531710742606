<template>
  <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
    <b-modal id="national-contract-modal" ref="upsertModal" hide-footer @hidden="onUpsertModalHidden">
      <template #modal-header>
        <div class="w-100 h-100 d-flex justify-content-between align-items-center">
          <template v-if="isEdit">
            <h4 class="mb-0">{{ $t("Update National Contract") }}</h4>
          </template>
          <template v-else>
            <h4 class="mb-0">{{ $t("Create National Contract") }}</h4>
          </template>
          <div class="ml-5 d-flex align-items-center">
            <b-button variant="success" class="mr-2" :class="{ 'spinner spinner-light spinner-right': isSaving }"
              @click="handleSubmit(submit)">
              {{ $t("Submit") }}
            </b-button>
            <b-button @click="closeUpsertModal">
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </div>
      </template>
      <b-col cols="12">
        <validation-provider v-slot="{ errors }" :name="$t('Country')" rules="required">
          <euro-select v-model="form.country" :disabled="isDisable" :options="countrySelectable" searchable
            :label="`${$t('Country')}*`" :error-messages="errors"></euro-select>
        </validation-provider>

        <validation-provider v-slot="{ errors }" :name="$t('Language')" rules="required">
          <euro-select v-model="form.language" :options="languageSelectable" searchable :label="`${$t('Language')}*`"
            :error-messages="errors"></euro-select>
        </validation-provider>

        <validation-provider v-slot="{ errors }" :name="$t('Name')" rules="required">
          <euro-input v-model="form.name" :label="`${$t('Name')}*`" :error-messages="errors"></euro-input>
        </validation-provider>

        <validation-provider v-slot="{ errors }" :name="$t('Description')">
          <euro-input v-model="form.description" :label="$t('Description')" :error-messages="errors"></euro-input>
        </validation-provider>
      </b-col>
    </b-modal>
  </validation-observer>
</template>
<script>
import { backendErrorSwal } from "@/core/helpers/swal";
import Swal from "sweetalert2";
import employeeNationalContractService from '@/core/services/employee/employee-nationalcontract.service';
import { getValidationErrors } from "@/core/helpers";
import { mapState } from "vuex";

export default {
  props: {
    nationalContract: {
      type: Object,
      default: null,
    },
    existingForm: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isSaving: false,
      apiErrors: null,
      form: {
        name: "",
        description: "",
        country: "",
        language: "",
      },
    }
  },
  computed: {
    ...mapState("constants", ["countries", "languages"]),
    isEdit() {
      return Object.keys(this.existingForm).length > 0
    },
    isDisable() {
      return !!(Object.keys(this.existingForm).length > 0 || this.$attrs.nation);
    },
    languageSelectable() {
      return this.languages.map(x => ({ text: x.name_en, value: x.iso_639_1 }));
    },
    countrySelectable() {
      return this.countries.map(x => ({ text: x.name, value: x.iso }));
    },
  },
  watch: {
    apiErrors: {
      deep: true,
      handler(val) {
        const formObs = this.$refs.validationObserver;
        const newErrors = getValidationErrors(val, this.form);
        formObs.setErrors({ ...newErrors });
      },
    },
    existingForm: {
      deep: true,
      handler(newExistingForm) {
        this.form = { ...newExistingForm };
        if (this.$attrs.nation) {
          this.form.country = this.$attrs.nation.iso
        }
      },
    },
  },
  async mounted() {
    if (this.existingForm) {
      this.form = { ...this.form, ...this.existingForm };
    }
  },
  methods: {
    closeUpsertModal() {
      this.$bvModal.hide('national-contract-modal');
    },
    onUpsertModalHidden() {
      this.form = null;
      this.isSaving = false;
    },
    async submit() {
      this.isSaving = true;
      try {
        if (this.isEdit) {
          const form = { ...this.form }
          await employeeNationalContractService.update(form, form.id)
        } else {
          const create_form = { ...this.form }
          await employeeNationalContractService.create(create_form)
        }
        this.closeUpsertModal();
        this.$emit("refresh");
        let title = this.$t("Contract level created")
        if (this.isEdit) {
          title = this.$t("Contract level updated")
        }
        Swal.fire(this.$t("Success"), title, "success");
      } catch (err) {
        if (err?.response?.status === 400) {
          this.apiErrors = err.response.data;
        } else {
          console.log(err)
          let message = err?.response?.data?.detail ?? this.$t("Failed to create the contract level. Please try again.")
          if (this.isEdit) {
            console.log(err);
            message = err?.response?.data?.detail ?? this.$t("Failed to update the contract level. Please try again.")
          }
          backendErrorSwal(message, err);
        }
      }
      finally {
        this.isSaving = false;
      }
    },
  }
};

</script>